import parseJSON from 'date-fns/parseJSON';

type Formatter = (
  locale: string,
  exceptionHandler: (e) => void,
) => (...args: any) => string;

export const getDateFormatter: Formatter = (locale, handleException) => {
  return (date: string) => {
    const options: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    };
    try {
      return new Intl.DateTimeFormat(locale, options).format(parseJSON(date));
    } catch (ex) {
      handleException(ex);
      return '';
    }
  };
};

export const getTimeFormatter: Formatter = (locale, handleException) => {
  return (date: string) => {
    const options: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
    };
    try {
      return new Intl.DateTimeFormat(locale, options).format(parseJSON(date));
    } catch (ex) {
      handleException(ex);
      return '';
    }
  };
};

export const getCurrencyFormatter: Formatter = (locale, handleException) => {
  return (price: number, currency: string): string => {
    try {
      return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
      }).format(price);
    } catch (ex) {
      handleException(ex);
      return '';
    }
  };
};
